import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { call } from '../Methods';

function Footer () {
  const [loading,setLoading] = useState(true);
  const [socialMediaContent,setSocialMediaContent] = useState(null);
  const [contacts,setContacts] = useState(null);


  const loadData = async()=>{
    setLoading(true);
    const _socialMediaContent = await call.content.getContents({groupName:'SocialMedia'})
    setSocialMediaContent(_socialMediaContent);
    const _contacts = await call.content.getContents({groupName:'Contacts'})
    setContacts(_contacts);
    setLoading(false);
  }

  useEffect(()=>{
    loadData();
  },[])

  if(loading) return <div>Loading...</div>
  return (
    <React.Fragment>
      <footer className='footer-part'>
        <div className='top row g-0'>
          <nav className='nav col-12 col-sm-12 col-md-4'>
            <ul>
              <li className='list-header'>Contact</li>
              <li>
                <a href={`tel:${contacts.find(c=>c.name=='phone')?.["value"]}`} dangerouslySetInnerHTML={{ __html: contacts.find(c=>c.name=='phone')?.["value"] }}></a>
              </li>
              <li>
                <a href={`mailto:${contacts.find(c=>c.name=='email')?.["value"]}`} dangerouslySetInnerHTML={{__html: contacts.find(c=>c.name=='email')?.["value"]}}></a>
              </li>
              <li>
                <a dangerouslySetInnerHTML={{__html: contacts.find(c=>c.name=='address')?.["value"]}}></a>
              </li>
            </ul>
          </nav>
          <nav className='nav col-12 col-sm-6 col-md-4'>
            <ul>
              <li className='list-header'>Company Info</li>
              <li>
                <Link to='/about'>About Us</Link>
              </li>
              <li>
                <Link to='/terms-and-conditions'>Terms And Conditions</Link>
              </li>
              <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
              </li>
              <li>
              <Link to='/refund-policy'>Refund Policy</Link>
              </li>
              <li>
              <Link to='/shipping-policy'>Shipping Policy</Link>
              </li>
            </ul>
          </nav>
          <nav className='nav nav-social col-12 col-sm-6 col-md-4'>
            <ul>
            <li className="list-header">
                Social Media
              </li>
              {socialMediaContent.find(c=>c.name=='facebook') && <li>
                <a href={socialMediaContent.find(c=>c.name=='facebook')?.["value"]}><img src='/Icons/socialnetworks/facebook.png'/>Facebook</a>
              </li>}
              {socialMediaContent.find(c=>c.name=='x') && <li>
                <a href={socialMediaContent.find(c=>c.name=='x')?.["value"]}><img src='/Icons/socialnetworks/x.png'/>X</a>
              </li>}
              {socialMediaContent.find(c=>c.name=='instagram') && <li>
                <a href={socialMediaContent.find(c=>c.name=='instagram')?.["value"]}><img src='/Icons/socialnetworks/instagram.png'/>Instagram</a>
              </li>}
            </ul>
          </nav>
        </div>

        <div className='bottom'>
          <p>© {new Date().getFullYear()} The Pro Prints. All Rights Reserved</p>
        </div>
        <div className="whatsapp-link">
          <Link target='_blank' to={`${contacts.find(c=>c.name=='whatsappurl')?.["value"]}`}>
            <img src='/Icons/socialnetworks/whatsapp-logo.png'/>
          </Link>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
