import React, { useState, useEffect,useRef } from 'react'
import { call } from '../Methods'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import Input from '../Admin/_Components/Input'
import ValidateInput from '../ValidationDefaults'
import { useSelector, useDispatch } from 'react-redux'
import Variables from '../global'

function Contact () {
  const [contacts, setContacts] = useState('Loading...')
  const [requestTypes,setRequestTypes] = useState([]);
  const [loading, setLoading] = useState(true)
  const contentGroupName = 'Contacts'
  const formRef = useRef();
  const [searchParams,setSearchParams] = useSearchParams();

  window.scrollTo(0, 0);

  const ValidateAs = {
    fullname:['required','fullName'],
    email:['required','email'],
    phone:['required'],
    requestType: ['required'],
    message:['required']
  }
  


  const submitRequest =  async e => {
    e.preventDefault();
    let validationResults = [];
    [...e.target.elements].forEach(input => {

      if(ValidateAs[input.name]){
 
        let errorSpan = document.querySelector(`span.warning-message[for=${input.name}]`)

        let result = ValidateInput(input.name, ValidateAs[input.name], input.value);
        validationResults.push(result);

        if(!result.result){
            errorSpan.classList.add('show')
            errorSpan.innerText = result?.message;
        } 
      }
    })

    let validationResult = validationResults.every(r=>r.result===true);

    if(validationResult){
      let _request = {};
      [...e.target.elements].forEach(input => {
        _request[input.name] = input.type!='file' ? input.value : input.dataset['value'];
        if(input.type=='file'){
        }
      }) 
      
      //return;
      const _clientRequest = await call.clientRequest.newRequest(_request);
      if(_clientRequest.success){
        alert("Your request has been sent");
        formRef.current.reset();
      }else{
        alert("error")
      }
    }
  }

  const getData = async () => {
    setLoading(true)
    const _contactDetails = await call.content.getContents({
      groupName: contentGroupName
    })
    setContacts(_contactDetails);
    const _requestTypes = await call.clientRequest.getClientRequestTypes();
    setRequestTypes(_requestTypes.map(({id,name})=>{return {value:id,text:name}}));
    setLoading(false)
  }

  
  useEffect(() => {
    getData()
  }, [])

  if (loading) return <div className='loading'>Loading...</div>
  if (!contacts) return <div className='loading'>Something wrong happen...</div>
  return (
    <>
      <div className='page contact-page'>
        <div className='container'>
          <h1 className='page-header'>Contact Us</h1>
          <div className='contact-page-content row'>
            <div className='col-12 col-md-6'>
              <form
                className='form'
                id='client-request-form'
                ref={formRef}
                onSubmit={e => {
                  submitRequest(e)
                }}
              >
                <h3 className='form-header'>
                  Have a question for us? We do our best to respond to
                  everything within 24 hours.
                </h3>
                <div className='input-box'>
                  <Input
                    type='text'
                    fieldName={'fullName'}
                    label={'Full Name'}
                    required={true}
                  />
                </div>
                <div className='input-box'>
                  <Input
                    type='text'
                    fieldName={'email'}
                    label={'E-mail'}
                    required={true}
                  />
                </div>
                <div className='input-box'>
                  <Input
                    type='text'
                    fieldName={'phone'}
                    label={'Phone'}
                    required={true}
                  />
                </div>
                <div className='input-box'>
                  <Input
                    type='select'
                    fieldName={'requestType'}
                    label={'Subject'}
                    data={requestTypes}
                    required={true}
                  />
                </div>
                <div className='input-box'>
                  <Input
                    type='textarea'
                    fieldName={'message'}
                    label={'Message'}
                    required={true}
                  />
                </div>
                <div className='input-box'>
                  <Input
                    type={'file'}
                    fieldName={'attachmentBase64'}
                    multiple={false}
                    label={'Attachment (max-size: 2MB)'}
                    acceptFileFormats={'image/*,.pdf, .psd'}
                    required={false}
                    data-value={null}
                    data={null}
                  />
                </div>
                <div className='button-box'>
                  <button type='submit' className='button button-default'>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className='col-12 col-md-6'>
              {contacts && (
                <div className='contact-details'>
                  <div className='contact-info'>
                    <span className='icon'>
                      <img src='/Icons/telephone.png' />
                    </span>
                    <span className='info'>
                      <a
                        href={`tel:${
                          contacts.find(c => c.name == 'phone')?.['value']
                        }`}
                      >
                        {contacts.find(c => c.name == 'phone')?.['value']}
                      </a>
                    </span>
                  </div>
                  <div className='contact-info'>
                    <span className='icon'>
                      <img src='/Icons/mail.png' />
                    </span>
                    <span className='info'>
                      <a
                        href={`mailto:${
                          contacts.find(c => c.name == 'email')?.['value']
                        }`}
                      >
                        {contacts.find(c => c.name == 'email')?.['value']}
                      </a>
                    </span>
                  </div>
                  <div className='contact-info'>
                    <span className='icon'>
                      <img src='/Icons/placeholder.png' />
                    </span>
                    <span className='info'>
                      {contacts.find(c => c.name == 'address')?.['value']}
                    </span>
                  </div>
                  <div className='map'>
                    <iframe
                      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d537680.0821703869!2d-80.01026499067596!3d43.67453327081664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sus!4v1704390124402!5m2!1sen!2sus'
                      width='600'
                      height='450'
                      style={{ border: 0 }}
                      allowFullScreen=''
                      loading='lazy'
                      referrerPolicy='no-referrer-when-downgrade'
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
