import React, { useState, useEffect } from 'react'
import { call } from '../Methods'

function ShippingPolicy () {
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(true)
  const contentKey = 'Page.shippingpolicy'

  const getData = async () => {
    const _shippingPolicy = await call.content.getContents({
      fullName: contentKey
    })
    setContent(_shippingPolicy)
    setLoading(false)
  }
  useEffect(() => {
    getData()
  }, [])

  if(loading) return <div className="loading">Loading...</div>
  if(!content) return <div className="loading">Something wrong happen...</div>
  return (
    <>
      {!loading &&  content && <div className='page shipping-policy-page'>
        <div className='container'>
          <div className='logo'>
            <img src='/logo_horizontal_woslogan.png'></img>
          </div>
          <h1 className='page-header'>Shipping Policy</h1>
          <p dangerouslySetInnerHTML={{ __html: content.value }}></p>
        </div>
      </div>}
      {loading && <div className="loading">Loading...</div>}
    </>
  )
}

export default ShippingPolicy;