import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Input from '../_Components/Input'
import Variables from '../../global'
import { call } from '../../Methods'
import MessageBox from '../_Components/MessageBox'
import { use } from '../../Helpers'

function _ItemEdit () {
  const { id } = useParams()
  const navigate = useNavigate()
  const [alertBoxParameters, setAlertBoxParameters] = useState({
    active: false,
    type: '',
    message: ''
  })
  const [loading, setLoading] = useState(true)
  const [item, setItem] = useState([])
  const [categories, setCategories] = useState([])

  let colorsByValues = {
    '-3': '#ff5252',
    '-2': 'black',
    '-1': '#c4c4c4',
    0: '#FF9800',
    1: '#4242c4',
    2: '#c442c4',
    3: '#42c442',
    4: '#c4c442',
    5: '#42c490'
  }

  const loadData = async () => {
    setLoading(true)
    let _categories = await call.forLoggedUser.category.getCategories({
      parentId: '',
      type: '',
      enabled: 1
    })
    if (_categories.success) {
      _categories = [
        ...categories,
        ..._categories.data.map(({ id, formattedName, parentId }) => {
          return {
            value: id,
            text: parentId ? '---' + formattedName : formattedName
          }
        })
      ]
    }

    setCategories(_categories)

    let _item = await call.forLoggedUser.item.getItem({ id: id })
    setItem(_item)
    setLoading(false)
  }
  useEffect(() => {
    loadData()
  }, [])

  const saveItem = async statusId => {
    setLoading(true)

    let size_price_array = []
    let sizes = Array.from(document.querySelectorAll('[name="size[]"]')).map(
      s => s.value
    )

    let substrates = Array.from(
      document.querySelectorAll('[name="substrate[]"]')
    ).map(s => s.value)

    let prices = Array.from(document.querySelectorAll('[name="price[]"]')).map(
      p => p.value
    )

    if (sizes.length > 0) {
      sizes.forEach((size, index) => {
        size_price_array.push({
          sizeId: size,
          substrateId: substrates[index],
          price: prices[index]
        })
      })
    }

    let images_array = []

    let uploaded_images = document.querySelectorAll(
      'img[name="input-image-preview[]"]'
    )
    for (let i = 0; i < uploaded_images.length; i++) {
      images_array.push({
        base64: uploaded_images[i].dataset['base64'] || null,
        order: +uploaded_images[i].dataset['order'],
        isNew: uploaded_images[i].dataset['isnew'] === 'true' ? true : false,
        imageUrl: uploaded_images[i].dataset['imageurl']
      })
    }

    let tags_array = use
      .getInputValueByName('tags')
      .split(' ')
      .map(tag => {
        return new Object({ tag: tag })
      })

    let item = {}
    item.id = id
    item.itemName = use.getInputValueByName('itemName')
    item.description = use.getInputValueByName('description')
    item.categoryId = use.getInputValueByName('categoryId')
    item.tags = tags_array
    item.prices = size_price_array
    item.images = images_array
    item.statusId = statusId
    setItem(item)
    //return;

    //return false;
    const response = await call.forLoggedUser.item.updateItem(item)

    setItem(response)
    if (response.id) {
      setAlertBoxParameters({
        active: true,
        type: 'success',
        message: 'Successfully saved',
        timeout: 5000
      })
    } else {
      setAlertBoxParameters({
        active: true,
        type: 'error',
        message: 'Something bad happened',
        timeout: 5000
      })
    }
    setLoading(false)
  }

  return (
    !loading &&
    item && (
      <React.Fragment>
        <form className='form'>
          <MessageBox parameters={alertBoxParameters} />
          <h4 className='form-title'>
            {item.itemNumber}
            <span
              style={{
                marginLeft: '20px',
                backgroundColor: colorsByValues[item.statusId]
              }}
              className='status-label'
            >
              {item.statusName}
            </span>
          </h4>
          <div className='row'>
            <Input
              type={'text'}
              label={'Name'}
              fieldName={'itemName'}
              col={{ default: 12, md: 4 }}
              value={item.itemName}
            />
            <Input
              type={'text'}
              label={'Tags'}
              fieldName={'tags'}
              col={{ default: 12, md: 5 }}
              value={item.tags.map(t => t.tag).join(' ')}
            />

            {categories && (
              <Input
                type={'select'}
                label={'Category'}
                fieldName={'categoryId'}
                col={{ default: 12, md: 3 }}
                data={categories}
                value={item.categoryId}
              />
            )}

            <Input
              type={'textarea'}
              label={'Description'}
              fieldName={'description'}
              col={{ default: 12, sm: 12, md: 12 }}
              value={item.description}
            />
            <Input
              type={'image'}
              label={'Images'}
              fieldName={'images'}
              col={{ default: 12, sm: 12, md: 12 }}
              value={item.images}
              multiple={true}
              data={item.images.map(
                image =>
                  new Object({
                    src: `${Variables.base_url}/api/images/thumbnail-md/${image.imageUrl}`,
                    srclarge: `${Variables.base_url}/api/images/large/${image.imageUrl}`,
                    data: null,
                    isNew: false,
                    imageurl: image.imageUrl,
                    order: image.order
                  })
              )}
              eventHandler={{
                type: 'change',
                handler: function (e) {
                  let target = e.target
                }
              }}
            />
          </div>

          {item && (
            <SizePriceRows //replace with item.prices is not working. fix it!  //working [{ sizeId: 1, price: 2 }]
              prices={item.prices}
            />
          )}

          <div className='action-buttons'>
            <button
              type='button'
              className='back-button'
              onClick={function () {
                navigate('/admin/items', { replace: true })
              }}
            >
              Back
            </button>
            <button
              type='button'
              className='save-button'
              onClick={function () {
                saveItem(item.statusId)
              }}
            >
              Save
            </button>
            {item.statusId === 1 && (
              <button
                type='button'
                className='draft-button'
                onClick={function () {
                  saveItem(0)
                }}
              >
                Save & Unpublish
              </button>
            )}
            {item.statusId === 0 && (
              <button
                type='button'
                className='publish-button'
                onClick={function () {
                  saveItem(1)
                }}
              >
                Save & Publish
              </button>
            )}
            <button
              type='button'
              className='delete-button'
              onClick={function () {
                saveItem(-1)
                navigate('/admin/items', { replace: true })
              }}
            >
              Delete
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  )
}

function SizePriceRows ({ prices }) {
  const [sizePrices, setSizePrices] = useState([...prices])

  const [sizeList, setSizeList] = useState([
    { value: 0, text: 'Standard Size' }
  ])
  const [substrateList, setSubstrateList] = useState([
    { value: 0, text: 'Default' }
  ])

  const [loading, setLoading] = useState(false)

  const token = localStorage.getItem('tpp_token')

  const addSizeRow = e => {
    let _new = [...sizePrices]
    _new.push({ sizeId: 0, substrateId: 0, price: 0 })
    setSizePrices(_new)
  }

  const removePriceRow = e => {
    setLoading(true)
    let id = e.target.dataset['id']
    var row = document.querySelector(`div.row[data-index="${id}"]`)
    let _sizePrices = [...sizePrices].filter(a => a.item_PriceId != id)
    setSizePrices(_sizePrices)
    setLoading(false)
  }

  const showInfo = () => {
    alert(
      'You have disabled one of the item parameters. This row will not be shown in the client side.'
    )
  }

  const getData = async () => {
    setLoading(true)

    const _sizeResponse = await call.forLoggedUser.size.getSizes({})

    const _substrateResponse = await call.forLoggedUser.substrate.getSubstrates(
      {}
    )

    let _substrateList = [],
      _sizeList = []

    if (_sizeResponse.success) {
      _sizeResponse.data.forEach(element => {
        _sizeList.push({
          value: element.id,
          text: element.name,
          enabled: element.enabled == true
        })
      })

      _sizeList = [...sizeList, ..._sizeList]
      setSizeList([..._sizeList].sort((a, b) => +b.enabled - +a.enabled))
    }

    if (_substrateResponse.success) {
      _substrateResponse.data.forEach(element => {
        _substrateList.push({
          value: element.id,
          text: element.name,
          enabled: element.enabled == true
        })
      })
      _substrateList = [...substrateList, ..._substrateList]
      setSubstrateList(
        [..._substrateList].sort((a, b) => +b.enabled - +a.enabled)
      )
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div id='size-price'>
      {!loading &&
        prices.length &&
        sizePrices.map((price, index) => {
          return (
            <div
              key={price.item_PriceId}
              data-index={price.item_PriceId}
              className='row sub-items-row'
            >
              <div className='col-12 col-sm-1 col-md-1'>
                <div className='input-box'>
                  <label></label>
                  <div>
                    <span className='item-number'>{index + 1}</span>
                  </div>
                </div>
              </div>
              <Input
                type={'select'}
                fieldName={'size[]'}
                label={'Size'}
                col={{ default: 12, sm: 4, md: 3 }}
                data={sizeList}
                disableSelectOptions={true}
                disableByValue={'enabled'}
                value={price.sizeId}
              />

              <Input
                type={'select'}
                fieldName={'substrate[]'}
                label={'Substrate'}
                col={{ default: 12, sm: 4, md: 2 }}
                data={substrateList}
                disableSelectOptions={true}
                disableByValue={'enabled'}
                value={price.substrateId}
              />

              <Input
                type={'number'}
                label={'Price'}
                fieldName={'price[]'}
                col={{ default: 12, sm: 3, md: 3 }}
                value={price.price}
              />

              <div className='col-12 col-sm-1 col-md-1'>
                <div className='input-box'>
                  <label></label>
                  <div>
                    <img
                      className='row-delete'
                      data-id={price.item_PriceId}
                      src='/trash.svg'
                      onClick={removePriceRow}
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-1 col-md-1'>
                {!loading &&
                  (!sizeList.find(_s => _s.value == price.sizeId)?.enabled ||
                    !substrateList.find(_s => _s.value == price.substrateId)
                      ?.enabled) && (
                    <div className='input-box'>
                      <label></label>
                      <div>
                        <span
                          className='row-info'
                          data-id={price.item_PriceId}
                          src='../../Icons/info_basic.svg'
                          onClick={showInfo}
                        >
                          !
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )
        })}
      <button type='button' className='add-size' onClick={addSizeRow}>
        Add Price
      </button>
    </div>
  )
}

export default _ItemEdit
