import React, { useEffect } from 'react'
import { call } from '../Methods';
import { useDispatch } from 'react-redux';
import { updateDashboardData } from '../Store/dashboardSlice';

function _Dashboard() {
    const dispatch = useDispatch();

    const getData = async()=>{
        const _data = await call.forLoggedUser.dashboard.getdashboardinfo();
        dispatch(updateDashboardData({_newOrders:_data.newOrders,_newRequests:_data.newRequests}));
    }

    useEffect(()=>{
        getData();
    },[])

    return (
        <React.Fragment>
            <h3 className="page-title">Dashboard</h3>
            <div className="admin-table">
                
            </div>
        </React.Fragment>
    );
}

export default _Dashboard;