import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Input from '../_Components/Input'
import Variables from '../../global'
import { call } from '../../Methods'
import MessageBox from '../_Components/MessageBox'

function _ItemAdd () {
  const navigate = useNavigate()
  const [alertBoxParameters,setAlertBoxParameters] = useState({active:false,type:'',message:''});
 
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([
    { value: 0, text: 'Select Category' }
  ])

  const formRef = useRef()
  let token = localStorage.getItem('tpp_token')

  const [item, setItem] = useState({
    id: undefined,
    images: [],
    itemName: undefined,
    statusId: undefined,
    statusName: undefined,
    prices: undefined,
    images: undefined,
    imagesStr: undefined,
    sizes: undefined,
    categoryId: undefined,
    categoryName: undefined,
    createdDate: undefined,
    modifiedDate: undefined,
    description: undefined,
    tags: undefined
  })

  const saveItem = async statusId => {
    setLoading(true)

    let size_price_array = []
    let sizes = Array.from(document.querySelectorAll('[name="size[]"]')).map(
      s => s.value
    )

    let substrates = Array.from(
      document.querySelectorAll('[name="substrate[]"]')
    ).map(s => s.value)

    let prices = Array.from(document.querySelectorAll('[name="price[]"]')).map(
      p => p.value
    )

    if (sizes.length > 0) {
      sizes.forEach((size, index) => {
        size_price_array.push({
          sizeId: size,
          substrateId: substrates[index],
          price: prices[index]
        })
      })
    }

    let images_array = []

    let uploaded_images = document.querySelectorAll(
      'img[name="input-image-preview[]"]'
    )
    for (let i = 0; i < uploaded_images.length; i++) {
      images_array.push({
        base64:   uploaded_images[i].dataset['base64'],
        order: +uploaded_images[i].dataset['order']
      })
    }

    let tags_array = getInputValueByName('tags').split(" ").map(tag=>{return new Object({tag:tag})})


    let item = {}
    item.itemName = getInputValueByName('itemName')
    item.description = getInputValueByName('description')
    item.categoryId = getInputValueByName('categoryId')
    item.tags = tags_array
    item.prices = size_price_array
    item.images = images_array
    item.statusId = statusId
    setItem(item)
    //return;
    const response = await call.forLoggedUser.item.createItem(item)

    
    if (response.id) {
      setItem(response);
      setAlertBoxParameters({active:true,type:'success',message:'Saved successfully',timeout:5000});
      formRef.current.reset();
      navigate(`/admin/item/${response.id}`,{replace:true})
    }else{
      setAlertBoxParameters({active:true,type:'error',message:'Something bad happened',timeout:5000});
    }

    setLoading(false)
  }

  const getDatasetValueByName = (name, dataKey) => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    return element.map(el => el.dataset[dataKey])
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  const loadData = async() => {
    let _categories = await call.forLoggedUser.category.getCategories({parentId:'',type:'',enabled:1});
    if(_categories.success){         
      _categories = [...categories,..._categories.data.map(({id,formattedName}) => {return { value: id, text: formattedName }})];
      setCategories(_categories);
    }
    

  }


  useEffect(() => {
    loadData();
  }, [])

  return (
    <React.Fragment>
      <form className='form' ref={formRef}>
        <MessageBox parameters={alertBoxParameters}/>
        <h4 className='form-title'>{'Add New Item'}</h4>
        {loading && <div className='loader'>Loading...</div>}
        <div className='row'>
          <Input type={'hidden'} fieldName={'id'} />
          <Input
            type={'text'}
            label={'Name'}
            fieldName={'itemName'}
            col={{ default: 12, md: 4 }}
            validate={function (value) {
              if (value == '0') {
                return false
              }
            }}
          />

          <Input
            type={'text'}
            label={'Tags'}
            fieldName={'tags'}
            col={{ default: 12, md: 5 }}
            value={null}
            data={null}
          />

          {categories && <Input
            type={'select'}
            label={'Category'}
            fieldName={'categoryId'}
            col={{ default: 12, md: 3 }}
            value={0}
            data={categories}
          />}

          <Input
            type={'textarea'}
            label={'Description'}
            fieldName={'description'}
            col={{ default: 12, sm: 12, md: 12, lg: 12 }}
          />

          <Input
            type={'image'}
            label={'Images'}
            fieldName={'images'}
            multiple={true}
            col={{ default: 12, sm: 12, md: 12 }}
            value={null}
            data={[]}
          />
        </div>

        <SizePriceRows/>

        <div className='action-buttons'>
          <button
            type='button'
            className='back-button'
            onClick={function () {
              navigate('/admin/items', { replace: true })
            }}
          >
            Back
          </button>
          <button
            type='button'
            className='draft-button'
            onClick={function () {
              saveItem(0)
            }}
          >
            Save as Draft
          </button>
          <button
            type='button'
            className='save-button'
            onClick={function () {
              saveItem(1)
            }}
          >
            Publish
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

function SizePriceRows () {
  const [sizePrices, setSizePrices] = useState([
    { sizeId: null, substrateId: null, price: null }
  ])
  const [sizeList, setSizeList] = useState([
    { value: '0', text: 'Standard Size' }
  ])
  const [substrateList, setSubstrateList] = useState([
    { value: '0', text: 'Default' }
  ])

  const [loading, setLoading] = useState(false)


  const addSizeRow = () => {
    let _new = [...sizePrices]
    _new.push({ id:_new.length,sizeId: 0, substrateId: 0, price: 0 })
    setSizePrices(_new)
  }

  const removePriceRow = e => {
    setLoading(true);
    let id = e.target.dataset['id']
    var row = document.querySelector(`div.row[data-index="${id}"]`)
    let _sizePrices = [...sizePrices].filter(a => a.id != id)
    setSizePrices(_sizePrices);
    setLoading(false)
  }


  useEffect(() => {
    const getData = async () => {
      const _sizeResponse = await call.size.getSizes({enabled:1})

      const _substrateResponse = await call.substrate.getSubstrates({enabled:1})

      let _substrateList = []
      let _sizeList = []

      _substrateResponse.forEach(element => {
        _substrateList.push({ value: element.id, text: element.name })
      })
      _substrateList = [...substrateList, ..._substrateList]
      setSubstrateList([..._substrateList])

      _sizeResponse.forEach(element => {
        _sizeList.push({ value: element.id, text: element.name })
      })
      _sizeList = [...sizeList, ..._sizeList]
      setSizeList([..._sizeList])
    }

    getData()
  }, [])

  return (
    <div id='size-price'>
      {!loading && sizePrices?.map((sizePrice, index) => {
        return (
          <div key={index} data-index={index} className='row sub-items-row'>
            <div className='col-12 col-sm-1 col-md-1'>
              <div className='input-box'>
                <label></label>
                <div>
                  <span className='item-number'>{index + 1}</span>
                </div>
              </div>
            </div>
            {(
              <Input
                type={'select'}
                fieldName={'size[]'}
                label={'Size'}
                col={{ default: 12, sm: 4, md: 3 }}
                data={sizeList}
                value={0}
              />
            )}

            {(
              <Input
                type={'select'}
                fieldName={'substrate[]'}
                label={'Substrate'}
                col={{ default: 12, sm: 4, md: 2 }}
                data={substrateList}
                value={0}
              />
            )}

            <Input
              type={'number'}
              label={'Price'}
              fieldName={'price[]'}
              col={{ default: 12, sm: 3, md: 3 }}
              value={0}
            />
            <div className='col-12 col-sm-1 col-md-1'>
              <div className='input-box'>
                <label></label>
                <div>
                  <img
                    className='row-delete'
                    data-id={index}
                    src='/trash.svg'
                    onClick={removePriceRow}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <button type='button' className='add-size' onClick={addSizeRow}>
        Add Price
      </button>
    </div>
  )
}

export default _ItemAdd
