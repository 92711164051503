import React, {useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Variables from '../global'

function ItemCard ({ item }) {




  return (
    <React.Fragment>
      <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 item-container'>
        <div className='item-card'>
          <Link className='item-card-link' to={`/item/${item.id}`}>
          <span className="item-number">{item.itemNumber}</span>
            <div className='item-image'>
              <img
                src={`${Variables.base_url}/api/images/thumbnail-md/${item.image}`}
              />
            </div>
            
            <div className='item-name'>{item?.itemName?.length > 80 ? item.itemName.substring(0,80).concat("..."):item.itemName}</div>
          </Link>
          <div className='item-price'>From $ {item.minPrice}</div>
          
        </div>
      </div>
    </React.Fragment>
  )
}

export default ItemCard
