import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Variables from '../global'
import { use } from '../Helpers'
import { updateItem } from '../Store/cartSlice'
import { Link } from 'react-router-dom'

export default function CheckOutItem ({ _item }) {
  const dispatch = useDispatch()
  const item = useSelector(state =>
    state.cart.items.find(i => i.priceId == _item.price.id)
  )

  const _price = _item.price

  function increaseItemCount () {
    if (_price.enabled == 0) {
      return false
    }
    let itemCount = item.count + 1
    dispatch(updateItem({ priceId: item.priceId, count: itemCount }))
  }

  function decreaseItemCount () {
    if (_price.enabled == 0) {
      return false
    }
    let itemCount = item.count - 1
    if (itemCount === 0) {
      let x = window.confirm('are you sure?')
      if (x) {
        dispatch(updateItem({ priceId: item.priceId, count: 0 }))
      }
      return
    }
    dispatch(updateItem({ priceId: item.priceId, count: itemCount }))
  }

  function removeItem () {
    let x = window.confirm('are you sure?')
    if (x) {
      dispatch(updateItem({ priceId: item.priceId, count: 0 }))
    }
  }

  return (
    <React.Fragment>
      {item && (
        <div
          className={`check-out-item${_price.enabled == 0 ? ' disabled' : ''}`}
        >
          {_price.enabled == 0 && (
            <span className='price-is-not-active'>
              Price is changed. Please, add the item with current price.
            </span>
          )}
          <div className='row main-content'>
            <div className='col-3'>
              <img
                src={`${Variables.base_url}/api/images/thumbnail-md/${
                  _item.image
                }`}
              />
            </div>
            <div className='col-9' style={{padding:'10px'}}>
            <span className="item-number">{_item.itemNumber}</span>
              <div className='item-name'>
                
                <Link to={`/item/${_item.id}`}>{_item.itemName}</Link>
                
              </div>
              <div className='item-parameters'>
                {_price.sizeName && (
                  <div className='item-parameter'>
                    <span className="parameter-name">Size:</span> {_price.sizeName}
                    </div>
                )}
                {_price.substrateName && (
                  <div className='item-parameter'>
                    <span className="parameter-name">Substrate:</span> {_price.substrateName}
                  </div>
                )}
              </div>
              <div className='row operations-row'>
                <div className='col-4'>
                  <span className='item-count'>
                    <button onClick={decreaseItemCount}>-</button> <span className="count">{item.count}</span>
                    <button onClick={increaseItemCount}>+</button>
                  </span>
                </div>
                <div className='col-4'>
                  <span className='item-price'>
                    $ {use.precisionRound(_price.price * item.count, 2)}
                  </span>
                </div>
                <div className='col-4'>
                  <span className='remove-item'>
                    <button onClick={removeItem}>Remove item</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
