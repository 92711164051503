import React, { Fragment, useEffect, useRef, useState } from 'react'
import {  useLocation, useNavigate, Link } from 'react-router-dom'
import { use } from '../Helpers'
import { call } from '../Methods'
import Input from '../Admin/_Components/Input'
import ValidateInput from '../ValidationDefaults'


export default function ForgotPassword () {
  let navigate = useNavigate()
  const {state} = useLocation();



  const ValidateAs = {
    email: ['required', 'email']
  }

  async function Send (e) {
    e.preventDefault();

    let email = document.querySelector('.forgot-password-form input[name="email"]').value

    let validationResults = [];
    [...e.target.elements].forEach(input => {
      if (ValidateAs[input.name]) {
        let errorSpan = document.querySelector(
          `span.warning-message[for=${input.name}]`
        )

        let result = ValidateInput(
          input.name,
          ValidateAs[input.name],
          input.value
        )
        validationResults.push(result)

        if (!result.result) {
          errorSpan.classList.add('show')
          errorSpan.innerText = result?.message
        }
      }
    })

    let validationResult = validationResults.every(r => r.result === true)

    if (validationResult) {
      const _result = await call.user.forgotPassword({email:email});
      console.log(_result);
      if (_result?.success) {
        
        alert('Success')      
      }else{
        alert(_result?.error?.message)
      }
    }
  }



  return (
      <div className="page account-page">
        <img className="svg-background" src="/Backgrounds/wave-haikei.svg"/>
        <div className="account-forms">
        <div className="forgot-password-form form-container">
        <h1>
          Forgot Password
        </h1>
        <form
          className='form'
          noValidate
          method='post'
          onSubmit={e => {
            Send(e)
          }}
        >
          <Input
            label={'E-mail'}
            fieldName={'email'}
            type={'email'}
            validateAs={'email'}
          />
          <div className='button-box'>
            <button
              className='button button-default'
              type='submit'
            >
              Send
            </button>
          </div>
        </form>
        </div>
        </div>
      </div>

    )
}
