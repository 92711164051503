import { createSlice } from '@reduxjs/toolkit'
import { use } from '../Helpers.js'

if (!localStorage.getItem('tpp_cart')) {
  localStorage.setItem('tpp_cart', '[]')
}

const initialState = {
  items: JSON.parse(localStorage.getItem('tpp_cart')) || [],
  totalAmount: 0,
  hst: 0
}

const calculateTotalAmount = stateValue => {
  let cart = JSON.parse(localStorage.getItem('tpp_cart')) || []

  if (!cart?.length) {
    return 0
  } else if (cart.length === 1) {
    let item = cart[0]
    return use.precisionRound(+item.count * +item.price, 2)
  } else {
    return use.precisionRound(
      cart.reduce((a, b) => a + b.price * b.count, 0),
      2
    )
  }
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState: { ...initialState, totalAmount: calculateTotalAmount() || 0 },
  reducers: {
    changeTotalAmount: (state, action) => {
      state.totalAmount = action.payload
    },
    addItemToCart: (state, action) => {
      state.items = [...state.items, action.payload]
      localStorage.setItem('tpp_cart', JSON.stringify(state.items))
      state.totalAmount = calculateTotalAmount(action.payload)
      state.hst = (state.totalAmount * 13 / 100).toFixed(2)
    },
    updateItem: (state, action) => {
      let index = state.items.indexOf(
        state.items.find(i => i.priceId === action.payload.priceId)
      )
      if (action.payload.count === 0) {
        state.items.splice(index, 1)
      } else {
        state.items[index].count =
        action.payload.count || state.items[index].count
        state.items[index].active = action.payload.active
      }
      localStorage.setItem('tpp_cart', JSON.stringify(state.items));
      console.log('salam',state.items)
      state.totalAmount = calculateTotalAmount(state.items)
      state.hst = (state.totalAmount * 13 / 100).toFixed(2)
    },
    emptyCart: state => {
      state.items = []
      localStorage.setItem('tpp_cart', '[]')
    }
  }
})

// Action creators are generated for each case reducer function
export const { changeTotalAmount, addItemToCart, updateItem, emptyCart } =
  cartSlice.actions

export default cartSlice.reducer
