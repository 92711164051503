import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Cart from './Cart'
import { useDispatch, useSelector } from 'react-redux'
import { call } from '../Methods'

function Header () {
  const location = useLocation()
  const dispatch = useDispatch()
  const userShort = useSelector(state => state.user.userInfo)
  const mobileView = useSelector(state => state.viewSettings.isMobile)
  const [loading, setLoading] = useState(true)
  const [socialMediaContent, setSocialMediaContent] = useState(null)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const loadData = async () => {
    setLoading(true)
    const _socialMediaContent = await call.content.getContents({
      groupName: 'SocialMedia'
    })
    setSocialMediaContent(_socialMediaContent)
    setLoading(false)
  }

  useEffect(() => {
    setShowMobileMenu(false)
  }, [mobileView])

  useEffect(() => {
    loadData()
  }, [])

  const scrollHandler = e => {
    let header = document.getElementsByTagName('header')[0]
    console.log(header)
    let logo = document.querySelector('img#webpage_logo')
    function onScroll () {
      if (window.scrollY > 180) {
        header?.classList.add('dark')
        logo.src = '/Logos/original2.png'
      } else {
        header?.classList.remove('dark')
        logo.src = '/Logos/original.png'
      }
    }
    if (header != null && logo != null) {
      document.addEventListener('scroll', onScroll)
    } else {
      document.removeEventListener('scroll', onScroll)
    }
  }

  useEffect(() => {
    scrollHandler()
  }, [])

  const toggleLoggedClientMenu = (e, close = false) => {
    if (close) {
      e.target.classList.remove('open')
    } else {
      e.target.classList.toggle('open')
    }
  }

  const toggleShowMobileMenu = () => {
    document.body.style.overflow = !showMobileMenu ? 'hidden' : 'scroll'
    setShowMobileMenu(showMobileMenu ? false : true)
  }

  const selectMenuItem = e => {
    let headerLinks = document.querySelectorAll('ul.nav-menu li.header-link a')
    headerLinks.forEach(l => l.classList.remove('selected'))
    e.target.classList.add('selected')
    setShowMobileMenu(false)
  }

  return (
    <>
      <header
        className={`header${
          location.pathname === '/' ? ' home-page-header' : ''
        }`}
      >
        <div className='logo'>
          <Link to='/'>
            <img
              id='webpage_logo'
              src='/Logos/original.png'
              alt='theproprints logo'
            />
          </Link>
        </div>
        <nav className={`header-menu`}>
          <ul
            className='nav-menu'
            style={mobileView ? { display: 'none' } : {}}
          >
            <li className='header-link'>
              <Link to='/' onClick={e => selectMenuItem(e)}>
                Home
              </Link>
            </li>
            <li className='header-link'>
              <Link to='/items' onClick={e => selectMenuItem(e)}>
                Products
              </Link>
            </li>
            <li className='header-link'>
              <Link to='/contact' onClick={e => selectMenuItem(e)}>
                Contact us
              </Link>
            </li>
          </ul>
          <div className='icons'>
            <ul className='icon-menu'>
              <li className='header-link'>
                <Link to='/items' onClick={e => selectMenuItem(e)}>
                  <div className='search'>
                    <svg
                      className='search-icon'
                      xmlns='http://www.w3.org/2000/svg'
                      id='Outline'
                      viewBox='0 0 24 24'
                      width='512'
                      height='512'
                    >
                      <path d='M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z' />
                    </svg>
                    <div className='search-box'></div>
                  </div>
                </Link>
              </li>
              <li className='header-link'>
                <Link to='/checkout' onClick={e => selectMenuItem(e)}>
                  <Cart />
                </Link>
              </li>
              <li className='header-link'>
                {!userShort && (
                  <Link to='/login' onClick={e => selectMenuItem(e)}>
                    <div className='account'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 101 101'
                        id='user'
                      >
                        <path d='M50.4 54.5c10.1 0 18.2-8.2 18.2-18.2S60.5 18 50.4 18s-18.2 8.2-18.2 18.2 8.1 18.3 18.2 18.3zm0-31.7c7.4 0 13.4 6 13.4 13.4s-6 13.4-13.4 13.4S37 43.7 37 36.3s6-13.5 13.4-13.5zM18.8 83h63.4c1.3 0 2.4-1.1 2.4-2.4 0-12.6-10.3-22.9-22.9-22.9H39.3c-12.6 0-22.9 10.3-22.9 22.9 0 1.3 1.1 2.4 2.4 2.4zm20.5-20.5h22.4c9.2 0 16.7 6.8 17.9 15.7H21.4c1.2-8.9 8.7-15.7 17.9-15.7z'></path>
                      </svg>
                    </div>
                  </Link>
                )}
                {userShort && (
                  <div
                    tabIndex={1}
                    className='account-info'
                    onClick={e => toggleLoggedClientMenu(e)}
                  >
                    {userShort.firstName[0]?.toUpperCase()}
                    {userShort.lastName[0]?.toUpperCase()}
                    <div className='user-menu'>
                      <ul>
                        <li>
                          <span id='user-fullname'>
                            {userShort.firstName} {userShort.lastName}
                          </span>
                        </li>
                        {userShort.roleId === 1 && (
                          <>
                            <li>
                              <Link to={`/dashboard/${userShort.userName}`}>
                                Profile
                              </Link>
                            </li>
                          </>
                        )}
                        {userShort.roleId === 2 && (
                          <>
                            <li>
                              <Link to={`/admin/dashboard`}>Dashboard</Link>
                            </li>
                          </>
                        )}
                        <li>
                          <Link to='/logout'>Sign out</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </li>
              <li
                className='header-link'
                style={mobileView ? { display: 'block' } : { display: 'none' }}
              >
                <div
                  className={`hamburger-menu${showMobileMenu ? ' open' : ''}`}
                  onClick={toggleShowMobileMenu}
                >
                  <div className='icon'>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <div
          className={`mobile-menu-container${showMobileMenu ? ' open' : ''}`}
        >
          <nav className='mobile-menu'>
            <ul>
              <li>
                <Link to={'/'} onClick={() => toggleShowMobileMenu()}>
                  Home
                </Link>
              </li>
              <li>
                <Link to={'/items'} onClick={() => toggleShowMobileMenu()}>
                  Products
                </Link>
              </li>
              <li>
                <Link to={'/contact'} onClick={() => toggleShowMobileMenu()}>
                  Contact Us
                </Link>
              </li>
              <li>
                <div className='socials-container'>
                  <label>We are on Social Media</label>
                  <ul className='nav-social'>
                    <li>
                      <a href='https://facebook.com' target='_blank'>
                        <img src='/Icons/socialnetworks/facebook.png' />
                      </a>
                    </li>
                    <li>
                      <a href='https://x.com' target='_blank'>
                        <img src='/Icons/socialnetworks/x.png' />
                      </a>
                    </li>
                    <li>
                      <a href='https://instagram.com' target='_blank'>
                        <img src='/Icons/socialnetworks/instagram.png' />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  )
}
export default Header
