import React from 'react'
import Variables from '../global'
import { Link } from 'react-router-dom'

function CategoryCard ({ category }) {
  return (
    <div className='category-card col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2'>
      <Link to={`/items?category=${category.id}`}>
        <img
          className='category-image'
          src={`${Variables.base_url}/api/Images/thumbnail-md/${category.image.imageUrl}`}
        />
        <div className='category-name'>
          <h3>{category.name}</h3>
        </div>
      </Link>
    </div>
  )
}

export default CategoryCard
