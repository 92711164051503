import React, { useState, useEffect } from 'react'
import { call } from '../Methods'

function About () {
  const [content, setContent] = useState(null)
  const [loading, setLoading] = useState(true)
  const contentKey = 'Page.about'

  const getData = async () => {
    setLoading(true)
    const _aboutPage = await call.content.getContents({
      fullName: contentKey
    })
    setContent(_aboutPage)
    setLoading(false)
  }
  useEffect(() => {
    getData()
  }, [])

  if(loading) return <div className="loading">Loading...</div>
  if(!content) return <div className="loading">Something wrong happen...</div>
  return (
    <>
      <div className='page about-page'>
        <div className='container'>
          <div className='logo'>
            <img src='/logo_horizontal_woslogan.png'></img>
          </div>
          <h1 className='page-header'>About Us</h1>
          <p  dangerouslySetInnerHTML={{ __html: content.value }}></p>
        </div>
      </div>
    </>
  )
}

export default About;
