import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

function Input ({
  type,
  label,
  fieldName,
  eventHandler = null,
  data = [],
  col = null,
  disabled = false,
  disableSelectOptions = false,
  disableByValue = null,
  required = false,
  multiple = false,
  acceptFileFormats = {},
  acceptImageFormats = 'image/*',
  value,
  className = '',
  style={},
  errorMessage = ''
}) {
  //configure input position in form
  let columnClassName = ''
  let uid = window.crypto.randomUUID().split('-')[0]
  if (col) {
    Object.keys(col).forEach(key => {
      columnClassName +=
        key == 'default' ? ' col-' + col[key] : ' col-' + key + '-' + col[key]
    })
  }
  const [element, setElement] = useState(undefined);

  //select
  const [options, setOptions] = useState(data);

  //input type checkbox
  const [checked, setChecked] = useState(value);

  //input type file -image
  const [images, setImages] = useState(data || []);
  const [files, setFiles] = useState(data || []);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const warningRef = useRef(null);
  const previewRef = useRef(null);
  useEffect(() => {
    if (type === 'select') {
      inputRef.current.value = value || data[0]?.value;
    }
    if (type !== 'image') {
      inputRef.current.addEventListener('focus', () => {
        warningRef.current.classList.remove('show')
      });
      
      if (eventHandler) {
        let _element = inputRef.current;
        if(eventHandler.length){
          eventHandler.forEach(handler=>{
            _element.removeEventListener(handler.type, handler.handler)
            _element.addEventListener(handler.type, handler.handler)
          })
        }else{
          _element.removeEventListener(eventHandler.type, eventHandler.handler)
          _element.addEventListener(eventHandler.type, eventHandler.handler)
        }
      }
    }
  }, [])

  const renderInput = () => {
    switch (type) {
      case 'textarea':
        return (
          <React.Fragment>
            <label htmlFor={uid}>
              {label}
              {required ? <span className='required'>*</span> : ''}
            </label>
                <textarea
                  name={fieldName}
                  id={uid}
                  ref={inputRef}
                  defaultValue={value}
                  className={className}
                  autoComplete='off'
                  noValidate
                ></textarea>

            <span
              ref={warningRef}
              className='warning-message'
              htmlFor={fieldName}
            ></span>
          </React.Fragment>
        )
      case 'select':
        return (
          <React.Fragment>
            <label htmlFor={uid}>
              {label}
              {required ? <span className='required'>*</span> : ''}
            </label>
            <select
              id={uid}
              name={fieldName}
              ref={inputRef}
              disabled={disabled}
            >
              {data.map(option => {
                return (
                  <option
                    disabled={disableSelectOptions && !option[disableByValue]}
                    key={option.value}
                    value={option.value}
                  >
                    {option.text}
                  </option>
                )
              })}
            </select>
            <span
              ref={warningRef}
              className='warning-message'
              htmlFor={fieldName}
            ></span>
          </React.Fragment>
        )
      case 'checkbox':
        return (
          <React.Fragment>
            <input
              type={type}
              ref={inputRef}
              name={fieldName}
              id={uid}
              disabled={disabled}
              defaultChecked={checked}
              defaultValue={value}
              className={className}
              autoComplete='off'
            />
            <label htmlFor={uid}>
              {label}
              {required ? <span className='required'>*</span> : ''}
            </label>
            <span
              ref={warningRef}
              className='warning-message'
              htmlFor={fieldName}
            ></span>
          </React.Fragment>
        )
      case 'image':
        return (
          <React.Fragment>
            <label htmlFor={uid}>{label}</label>
            <div className='input-image-wrapper'>
              <div className='add-image'>
                <input
                  type={'file'}
                  id={uid}
                  name={fieldName}
                  ref={inputRef}
                  disabled={disabled}
                  multiple={multiple}
                  accept={acceptImageFormats}
                  onChange={previewPhoto}
                  data-value={images}
                  className={className}
                />
              </div>
              <div
                className='zoom-image'
                ref={previewRef}
                data-currentindex={0}
              >
                <img />
                <div className='close-image' onClick={closeImage}>
                  &times;
                </div>
                <div
                  className='slide-button previous'
                  onClick={() => {
                    slideImage(-1)
                  }}
                >
                  &#8249;
                </div>
                <div
                  className='slide-button next'
                  onClick={() => {
                    slideImage(1)
                  }}
                >
                  &#8250;
                </div>
              </div>
              <div className='input-images'>
                {!loading &&
                  [...images].length > 0 &&
                  [...images].map((image, index) => {
                    return (
                      <div key={index} className='input-image'>
                        <div className='remove'>
                          <button
                            type='button'
                            data-index={index}
                            className='remove-button'
                            onClick={removeImage}
                          >
                            &times;
                          </button>
                        </div>
                        <img
                          className='image-preview'
                          name={`input-image-preview${multiple ? '[]' : ''}`}
                          src={image.src}
                          data-base64={image.base64}
                          data-srclarge={image.srclarge}
                          data-imageurl={image.imageurl}
                          data-order={image.order}
                          data-isnew={image.isNew}
                          //data-value={JSON.stringify(image)}
                          onClick={zoomImage}
                        />
                      </div>
                    )
                  })}
              </div>
            </div>
          </React.Fragment>
        )
      case 'file':
        return(
            <React.Fragment>
               <label htmlFor={uid}>
                {label}
                {required ? <span className='required'>*</span> : ''}
              </label>
              <input
                type={'file'}
                ref={inputRef}
                name={fieldName}
                id={uid}
                disabled={disabled}
                className={className}
                onChange={getBase64}
                multiple={multiple}
                data-value={null}
                accept={acceptFileFormats}
                autoComplete='off'
              />
              <span
                ref={warningRef}
                className='warning-message'
                htmlFor={fieldName}
              ></span>
            </React.Fragment>
        )
      default:
        return (
          <React.Fragment>
            <label htmlFor={uid}>
              {label}
              {required ? <span className='required'>*</span> : ''}
            </label>
            <input
              type={type}
              required={required}
              id={uid}
              ref={inputRef}
              name={fieldName}
              disabled={disabled}
              defaultValue={value}
              className={className}
              style={style}
              autoComplete='off'
              noValidate
              //ValidateInput(validateAs)
            />
            <span
              ref={warningRef}
              className={`warning-message${errorMessage ? ' show' : ''}`}
              htmlFor={fieldName}
            >
              {errorMessage}
            </span>
          </React.Fragment>
        )
    }
  }

  useLayoutEffect(() => {
    
  })

  const getBase64 = e => {
    setLoading(true)
    let fileList = Array.from(e.target.files);
    let fileReader = null;
    let _files = multiple? [...files] : [];
    if (fileList.length > 0) {
      fileList.forEach(file => {
        let _file;
        fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onloadend = ev => {
          _file = {
            base64: ev.target.result,
            name: file.name,
            order: [...files].length + 1,
            size: file.size,
            type: file.type,
            isNew: true,
            src: URL.createObjectURL(file)
          }
          e.target.dataset['value'] = ev.target.result;
          _files.push(_file)
          setFiles(_files)
        }
      })
    }
    setLoading(false)
  }

  const previewPhoto = e => {
    setLoading(true)
    let fileList = Array.from(e.target.files)
    let fileReader = null
    if (fileList.length > 0) {
      fileList.forEach(image => {
        let _image
        fileReader = new FileReader()
        fileReader.readAsDataURL(image)
        let _images = [...images]

        fileReader.onloadend = ev => {
          _image = {
            base64: ev.target.result,
            name: image.name,
            order: [...images].length + 1,
            size: image.size,
            type: image.type,
            isNew: true,
            src: URL.createObjectURL(image)
          }
          if (!multiple) {
            _images = []
          }
          _images.push(_image)
          setImages(_images)
        }
      })
    }
    e.target.value = null
    setLoading(false)
  }

  const removeImage = e => {
    let _idx = e.target.dataset['index']
    let imagesArray = [...images]
    imagesArray.splice(_idx, 1)
    imagesArray.map((i, index) => {
      i.order = index + 1
    })
    setImages(imagesArray)
  }

  const zoomImage = e => {
    let _imageWindow = previewRef.current
    _imageWindow.style.display = 'block'
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    _imageWindow.querySelector('img').src = e.target.dataset.srclarge
  }

  const closeImage = e => {
    let _imageWindow = previewRef.current
    _imageWindow.style.display = 'none'
    document.getElementsByTagName('body')[0].style.overflow = 'auto'
  }

  const slideImage = index => {
    let nextIndex = currentIndex + index

    let _imageList = [...images]
    if (nextIndex < 0) {
      nextIndex = _imageList.length - 1
    } else if (nextIndex > _imageList.length - 1) {
      nextIndex = 0
    }
    setCurrentIndex(nextIndex)
    let _imageWindow = previewRef.current
    _imageWindow.querySelector('img').src = _imageList[nextIndex].srclarge
  }

  return (
    <div className={columnClassName}>
      <div
        className={`input-box${disabled ? ' disabled' : ''}`}
        data-type={type}
      >
        {renderInput()}
      </div>
    </div>
  )
}

export default Input
