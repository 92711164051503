import React, { useState, useEffect, useRef } from 'react'
import { call } from '../Methods'
import Input from '../Admin/_Components/Input'
import ValidateInput from '../ValidationDefaults'

function ContactForm ({ requestTypeList = [] }) {
  const [contacts, setContacts] = useState('Loading...')
  const [requestTypes, setRequestTypes] = useState([{value:1,text:''}])
  const [loading, setLoading] = useState(false)
  const formRef = useRef()

  const ValidateAs = {
    fullname: ['required', 'fullName'],
    email: ['required', 'email'],
    phone: ['required'],
    requestType: ['required'],
    message: ['required']
  }

  const submitRequest = async e => {
    e.preventDefault()
    let validationResults = []
    ;[...e.target.elements].forEach(input => {
      if (ValidateAs[input.name]) {
        let errorSpan = document.querySelector(
          `span.warning-message[for=${input.name}]`
        )

        let result = ValidateInput(
          input.name,
          ValidateAs[input.name],
          input.value
        )
        validationResults.push(result)

        if (!result.result) {
          errorSpan.classList.add('show')
          errorSpan.innerText = result?.message
        }
      }
    })

    let validationResult = validationResults.every(r => r.result === true)

    if (validationResult) {
      let _request = {}
      ;[...e.target.elements].forEach(input => {
        _request[input.name] =
          input.type != 'file' ? input.value : input.dataset['value']
        if (input.type == 'file') {
        }
      })

      //return;
      const _clientRequest = await call.clientRequest.newRequest(_request)
      if (_clientRequest.success) {
        alert('Your request has been sent')
        formRef.current.reset()
      } else {
        alert('error')
      }
    }
  }

  useEffect(() => {
  
  }, [])

  if (loading) return <div className='loading'>Loading...</div>
  if (!contacts) return <div className='loading'>Something wrong happen...</div>
  return (
    <>
      <div className='contact-form'>
        <div className='container'>
          <form
            className='form row'
            id='client-request-form'
            ref={formRef}
            onSubmit={e => {
              submitRequest(e)
            }}
          >
            <h3 className='form-header'></h3>
            <input type="hidden" value="1" name="requestType"/>
            <Input
              type='text'
              fieldName={'fullName'}
              label={'Full Name'}
              col={{ default: 12, sm: 4, md: 4 }}
              required={true}
            />
            <Input
              type='text'
              fieldName={'email'}
              label={'E-mail'}
              col={{ default: 12, sm: 4, md: 4 }}
              required={true}
            />
            <Input
              type='text'
              fieldName={'phone'}
              label={'Phone'}
              col={{ default: 12, sm: 4, md: 4 }}
              required={true}
            />
            <Input
              type='textarea'
              fieldName={'message'}
              label={'Message'}
              required={true}
            />
            <Input
              type={'file'}
              fieldName={'attachmentBase64'}
              multiple={false}
              label={'Attachment (max-size: 2MB)'}
              acceptFileFormats={'image/*,.pdf, .psd'}
              required={false}
              data-value={null}
              data={null}
            />
            <div className='button-box'>
              <button type='submit' className='button button-default'>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default ContactForm