import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CheckOutItem from '../Components/CheckOutItem'
import { useSelector, useDispatch } from 'react-redux'
import ValidateInput from '../ValidationDefaults.js'
import { call } from '../Methods.js'
import Input from '../Admin/_Components/Input.js'
import { updateItem, emptyCart } from '../Store/cartSlice.js'
import LoginForm from '../Components/LoginForm.js'
import { use } from '../Helpers.js'

function CheckOut () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user.userInfo)
  const cart = useSelector(state => state.cart)
  const cartItems = cart.items
  const totalAmount = cart.totalAmount
  const hst = cart.hst
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)
  const [countryList, setCountryList] = useState([])
  const formRef = useRef()
  const [clicked, setClicked] = useState(false)

  const ValidateAs = {
    ordererfullname: ['required', 'fullName'],
    ordereremail: ['required', 'email'],
    orderercity: ['required'],
    orderercountry: ['required', 'id'],
    ordererphone: ['required'],
    ordereraddress: ['required'],
    ordererpostalcode: ['required']
  }

  const submitOrder = async e => {
    setSending(true)
    e.preventDefault()

    let validationResults = []
    ;[...e.target.elements].forEach(input => {
      if (ValidateAs[input.name]) {
        let errorSpan = document.querySelector(
          `span.warning-message[for=${input.name}]`
        )

        let result = ValidateInput(
          input.name,
          ValidateAs[input.name],
          input.value
        )
        validationResults.push(result)

        if (!result.result) {
          errorSpan.classList.add('show')
          errorSpan.innerText = result?.message
        }
      }
    })

    let validationResult = validationResults.every(r => r.result === true)

    if (validationResult) {
      let _orderDetails = {}
      ;[...e.target.elements].forEach(input => {
        _orderDetails[input.name] = input.value
      })

      _orderDetails.userId = userInfo?.id || 0
      let order_items_array = []

      cartItems.forEach((item, index) => {
        order_items_array.push({
          item_PriceId: item.priceId,
          singleItemPrice: item.price,
          quantity: item.count
        })
      })

      _orderDetails.paymentTypeId = [
        ...document.getElementsByName('paymentTypeId')
      ].find(i => i.checked).value

      _orderDetails.orderItems = order_items_array

      const _order = (await userInfo)
        ? call.forLoggedUser.order.createOrder(_orderDetails)
        : call.order.createOrder(_orderDetails)
      if (_order.success) {
        alert('your order has been placed successfully')
        formRef.current.reset()
        dispatch(emptyCart())
      } else {
        alert('error')
        setSending(false)
      }
    }
    setSending(false)
  }

  const loadData = async () => {
    setLoading(true)

    const _countries = await call.country.getCountryList()
    setCountryList(
      _countries.map(({ id, name }) => {
        return { value: id, text: name }
      })
    )

    if (cartItems.length > 0) {
      let priceIds = cartItems.map(i => i.priceId)
      const _items = await call.item.getItemsByPriceIds({
        priceIds: priceIds.join(',')
      })

      _items.forEach(i => {
        dispatch(
          updateItem({
            priceId: i.price.id,
            active: i.price.enabled
          })
        )
      })
      setItems(_items)
    }
    setLoading(false)
  }

  const checkOut = async e => {
    console.log(userInfo)
    let _orderDetails = {}
    _orderDetails.userId = userInfo?.id
    let order_items_array = []

    cartItems.forEach((item, index) => {
      order_items_array.push({
        item_PriceId: item.priceId,
        singleItemPrice: item.price,
        quantity: item.count
      })
    })
    _orderDetails.ordererCountry = 1
    _orderDetails.orderItems = order_items_array
    _orderDetails.billingAddressIsSameWithShippingAddress = use.getInputValueByName('ask-billing');
    console.log(_orderDetails);
    //return;
    const payment = await call.forLoggedUser.order.createOrderPayment(
      _orderDetails
    )
    if (payment?.success) {
      console.log(payment.data.redirectUrl)
      window.location.replace(payment.data.redirectUrl)
    }
  }

  useEffect(() => {
    loadData()
    if (userInfo) {
      console.log('if', userInfo)
    }
  }, [])

  if (loading) return <div className='loading'>Loading...</div>
  if (!items && !countryList)
    return <div className='loading'>Something wrong happen...</div>
  return (
    <React.Fragment>
      <div className='page check-out-page'>
        <div className='container'>
          {items && countryList && (
            <div className='row'>
              <div
                className='col-12 col-sm-12 col-md-12 col-lg-7 check-out-items'
                style={{ padding: '0 15px' }}
              >
                {cartItems.length > 0 && items.length > 0 ? (
                  items.map((item, index) => {
                    return <CheckOutItem key={index} _item={item} />
                  })
                ) : (
                  <>
                  <div>No items added to cart</div>
                  <div>Go to <Link to="/items">Items</Link> page to add items.</div>
                  </>
                  
                )}
              </div>
              <div
                className='col-12 col-sm-12 col-md-12 col-lg-5'
                style={{ padding: '0 15px' }}
              >
                {
                  <div className='order-form'>
                    {sending && <div className='sending'>Please Wait...</div>}
                    <p className='order-checkout-header'>Order Details</p>
                    <ul className='items-list'>
                      {cartItems.length > 0 && items.map(i => {
                        let count = [...cartItems].find(
                          ci => ci.priceId == i.price.id
                        )?.count;
                        return (
                          <li className='item' key={i.itemNumber}>
                            <p className='item-name'>{i.itemName}</p>
                            <p className='item-price'>
                              <span className='count'>x {count}</span>
                              <span className='price'>
                                $ {use.normalizePrecision(count * i.price.price)}
                              </span>
                            </p>
                          </li>
                        )
                      })
                      }
                      {cartItems.length == 0 && 'No items added to cart'}
                    </ul>
                    <p className='sub-total conclusion'>
                      <span className='bold-label'>Order Subtotal:</span>
                      <span>$ {loading ? 0 : totalAmount}</span>
                    </p>
                    <p className='hst conclusion'>
                      <span className=''>HST:</span>
                      <span>$ {loading ? 0 : + hst}</span>
                    </p>
                    <p className='grand-total conclusion'>
                      <span className='bold-label'>Order Total:</span>
                      <span>$ {loading ? 0 : use.normalizePrecision(+hst + totalAmount)}</span>
                    </p>
                    {cartItems.length > 0 &&
                    <p className="ask-billing">
                      <input id="ask-billing" type="checkbox" name="ask-billing"/>
                      <label htmlFor="ask-billing">Billing address is same with the shipping address</label>
                    </p>}
                    <form
                      id='order-form'
                      className='form'
                      ref={formRef}
                      onSubmit={e => {
                        submitOrder(e)
                      }}
                      noValidate
                    >
                      <div style={{ display: 'none' }}>
                        <Input
                          fieldName={'ordererfullname'}
                          label={'Full Name'}
                          type={'text'}
                          disabled={sending}
                          col={{ default: 12 }}
                          validateAs={'fullName'}
                          required={true}
                        />
                        <Input
                          fieldName={'ordererphone'}
                          label={'Phone'}
                          type={'tel'}
                          disabled={sending}
                          col={{ default: 12 }}
                          validateAs={'phone'}
                          required={true}
                        />
                        <Input
                          fieldName={'ordereremail'}
                          label={'E-mail'}
                          type={'email'}
                          disabled={sending}
                          col={{ default: 12 }}
                          validateAs={'email'}
                          required={true}
                        />
                        <Input
                          fieldName={'ordereraddress'}
                          label={'Address'}
                          type={'address'}
                          disabled={sending}
                          col={{ default: 12 }}
                          validateAs={'address'}
                          required={true}
                        />
                        <div className='row'>
                          <div className='col-12 col-sm-6'>
                            <Input
                              fieldName={'orderercountry'}
                              label={'Country'}
                              type={'select'}
                              disabled={sending}
                              col={{ default: 12 }}
                              validateAs={'country'}
                              data={countryList}
                              required={true}
                            />
                          </div>
                          <div className='col-12 col-sm-6'>
                            <Input
                              fieldName={'orderercity'}
                              label={'City'}
                              type={'text'}
                              disabled={sending}
                              col={{ default: 12 }}
                              validateAs={'city'}
                              required={true}
                            />
                          </div>
                        </div>
                        <Input
                          fieldName={'ordererpostalcode'}
                          label={'ZIP / Postal code'}
                          type={'text'}
                          disabled={sending}
                          col={{ default: 12 }}
                          validateAs={'postalCode'}
                          required={true}
                        />
                        <div className='button-box'>
                          <button
                            type='submit'
                            name='submit-button'
                            disabled={
                              cartItems.length == 0 || sending ? true : false
                            }
                            className={
                              cartItems.length == 0 || sending
                                ? 'button button-default button-disabled'
                                : 'button button-default'
                            }
                          >
                            Confirm Order
                          </button>
                        </div>
                      </div>
                      <div className='stripe-button'>
                        <div className='button-box'>
                          <button
                            className='button stripe-checkout'
                            onClick={e =>
                              userInfo
                                ? checkOut(e)
                                : navigate('/login', {
                                    state: { url: '/checkout' }
                                  })
                            }
                          >
                            <span>
                              {!userInfo && 'Sign in to '}Checkout with
                            </span>
                            <img src='Icons/stripe.png' />
                          </button>
                        </div>
                        <img
                          className='supported-payments'
                          src='/Images/stripe.png'
                        />
                      </div>
                    </form>
                  </div>
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default CheckOut
