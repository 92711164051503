import React, { useEffect, useRef, useState } from 'react'
import {
  Link,
  useParams,
  useLocation,
  useSearchParams,
  useNavigation
} from 'react-router-dom'
import ItemCard from '../Components/ItemCard'
import Sidebar from '../Components/Sidebar.js'
import { call } from '../Methods.js'
import ItemsList from '../Components/ItemsList.js'

function Items () {
  const [searchQueryParameters, setSearchQueryParameters] = useSearchParams()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchMode, setSearchMode] = useState(false)
  const [showCategoriesMenu, setShowCategoriesMenu] = useState(false)
  const pageSize = 24
  const [responseInfo, setResponseInfo] = useState()
  const searchInputRef = useRef()

  function toggleCategoriesMenu () {
    setShowCategoriesMenu(!showCategoriesMenu);
  }

  const loadItems = async (
    {
      category,
      tags,
      pageSize = 24,
      pageNumber = responseInfo?.pageNumber || 1
    },
    empty = false
  ) => {
    setLoading(true)

    const _items = await call.item.getItems({
      categoryId: category > 0 ? category : '',
      tags,
      pageSize,
      pageNumber
    })

    //setResponseInfo((({pageNumber,pageSize,pageCount,length})=>{{pageNumber=pageNumber,pageSize=pageSize,pageCount=pageCount,length=length}})(itemResponse))

    setResponseInfo(
      (({ success, pageCount, length, pageSize, pageNumber }) => {
        return {
          success: success,
          pageCount: pageCount,
          length: length,
          pageSize: pageSize || 24,
          pageNumber: pageNumber || 1
        }
      })(_items)
    )

    if (pageNumber == 1) {
      setItems([..._items.data])
      window.scrollTo(0, 0)
      console.log(pageNumber)
    } else {
      setItems([...items, ..._items.data])
    }
    setLoading(false)
  }

  const loadMore = async () => {

    let prm = {}

    let category = searchQueryParameters.get("category"),
    tags = searchQueryParameters.get("tags"),
    pageNumber = responseInfo.pageNumber+1;

    if(category) prm.category = category;
    if(tags) prm.tags=tags;
    if(pageNumber) prm.pageNumber=pageNumber;


    setSearchQueryParameters(prm)

    setResponseInfo({
      ...responseInfo,
      pageNumber: responseInfo.pageNumber + 1
    })
  }

  const searchItemsByTags = async e => {
    setResponseInfo({
      ...responseInfo,
      pageNumber: 1
    })
    if (e.key === 'Enter') {
      let searchInput = document.getElementById('search-input')
      let inputValue = searchInput.value.trim()
      if (document.activeElement === searchInput && inputValue !== '') {
        setSearchMode(true)
        setSearchQueryParameters({ tags: inputValue,pageNumber:1 })
        let button = document.getElementById('search-button')
        button.focus()
      }
    }
  }

  const clearSearchInput = async () => {
    setSearchMode(false)
    setSearchQueryParameters({})
    document.getElementById('search-input').value = ''
  }

  useEffect(() => {
    let parameters = Object.fromEntries(searchQueryParameters.entries())

    if (!parameters.tags) {
      setSearchMode(false)
      searchInputRef.current.value = ''
    } else {
      setSearchMode(true)
      searchInputRef.current.value = parameters.tags
    }

    loadItems(parameters)
  }, [searchQueryParameters])

  useEffect(()=>{
    searchInputRef.current.focus();
  },[searchInputRef])

  return (
    <>
      <div className='page items-page'>
        {<Sidebar selected={searchQueryParameters} show={showCategoriesMenu} clickHandler={toggleCategoriesMenu}/>}
        <div className='items-list'>
          <div className='search-items'>
            <img src='/Icons/filters.png'
            className={`categories-button ${showCategoriesMenu?'open':''}`}
            onClick={toggleCategoriesMenu} 
            />
            <button id='search-button' className='search-button'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="#ffce00" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"/></svg>
            </button>
            {searchMode && (
              <button
                id='clear-results'
                className='clear-results-button'
                onClick={clearSearchInput}
              >
                clear
              </button>
            )}
            <input
              id='search-input'
              ref={searchInputRef}
              type='text'
              name='search-items'
              placeholder='Search by tags...'
              onKeyUp={searchItemsByTags}
            />
          </div>
          {items && items.length>0 &&  <>
          <ItemsList items={items} />
          <div className='load-more'>
            {responseInfo && (
              <>
              <div className="result-info">Results: Showing {
                   responseInfo.length > pageSize * +responseInfo.pageNumber
                     ? pageSize * +responseInfo.pageNumber
                     : responseInfo.length
                 } of {responseInfo.length}</div>
               <button
                id='load-more-button'
                className={
                  responseInfo.pageCount === responseInfo.pageNumber
                    ? 'disabled'
                    : ''
                }
                data-nextpage={responseInfo.pageNumber + 1}
                onClick={e => responseInfo.pageCount === responseInfo.pageNumber? false:loadMore(e)}
              >
                {loading
                  ? 'Loading...'
                  : `Load More`}
              </button>
              </>
             
            )}
          </div></>}
          {items.length==0 && <span>No items</span>}
          <div className='banner'>
            <img src='/Images/banner.jpg' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Items
