import React, { useEffect, useState } from 'react'
import { call } from '../Methods'
import CategoryCard from '../Components/CategoryCard'
import { useLocation } from 'react-router-dom'
import Variables from '../global'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PartnerCard from '../Components/PartnerCard'





function Home () {
  const [categories, setCategories] = useState([]);
  const [partners,setPartners] = useState([]);
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState(null)
  const location = useLocation()
  const contentNames = 'mainpageslider,mainpagebanner';

  const [sliderItems,setSliderItems] = useState([]);
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 8000,
    lazyLoad: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <div
        style={{
          borderRadius: "10px",
          padding: "10px",
          bottom:"0px"
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    )
  };

  window.scrollTo(0, 0);

  const getData = async () => {
    setLoading(true)
    const _categories = await call.category.getCategories({parentId:'',type:1,enabled:1});

    if(_categories.length){
      setCategories(_categories);
    }
    
    const _partners = await call.partner.getPartners({})

    if (_partners.length) {
      setPartners(_partners)
    }

    const _contents = await call.content.getContents({
      names: contentNames
    });

    setSliderItems([...JSON.parse(_contents.find(c=>c.name=='mainpageslider').value)].map((imageUrl, index)=> (
      <div class="slider-item" key={index}>
          <img src={`${Variables.base_url}/api/images/original/${imageUrl}`}/>
      </div>
    )))
    
    setContent(_contents)
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  if (loading) return <div className='loading'>Loading...</div>
  return (
    <React.Fragment>
      <main className='page home-page'>
        <section className='header-line'>
          <h1>
            Business is powered <b>by print</b>
          </h1>
        </section>
        <section className='slider'>
        {sliderItems && 
        <Slider  {...settings}>
          {sliderItems}
        </Slider >}
        </section>
        <div className='products-content home-page-section'>
          <h2 className='section-header'>SHOP</h2>
          <p className='subheader'>Explore Your Vision in Every Design!</p>
          <section className='categories section-container row g-0 justify-content-center'>
            {!loading &&
              categories &&
              categories.map((category, index) => {
                return <CategoryCard key={category.id} category={category} />
              })}
          </section>
        </div>
        {!loading && partners && <div className='products-content home-page-section'>
          <h2 className='section-header'>Trusted by</h2>
          <p className="subheader">
          Sign Together, Shine Together: Elevate Your Brand with Our Trusted Partnerships!
          </p>
          <section className='partners section-container row g-0 justify-content-center'>
            { partners.map((partner, index) => {
                return <PartnerCard key={partner.id} partner={partner} />
              })}
          </section>
        </div>}
      </main>
    </React.Fragment>
  )
}

export default Home