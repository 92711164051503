import React, { useEffect, useState } from 'react'
import {
  Navigate,
  Routes,
  Route,
  IndexRoute,
  Link,
  BrowserRouter,
  Switch,
  useNavigate,
  useLocation
} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import WebFont from 'webfontloader'

//Client Components
import Home from './Client/Home'
import Item from './Client/Item'
import Items from './Client/Items'
import About from './Client/About'
import CheckOut from './Client/CheckOut'
import PaymentResult from './Client/PaymentResult'
import NotFound from './Client/NotFound'
import Contact from './Client/Contact'
import ClientLayout from './Client/ClientLayout'
import Redirect from './Client/Redirect'
import Logout from './Client/Logout'
import Login from './Client/Login'
import ForgotPassword from './Client/ForgotPassword'
import ResetPassword from './Client/ResetPassword'
import Register from './Client/Register'
import TermsAndConditions from './Client/TermsAndConditions'
import PrivacyPolicy from './Client/PrivacyPolicy'
import RefundPolicy from './Client/RefundPolicy'
import ShippingPolicy from './Client/ShippingPolicy'
import ClientDashboard from './Client/ClientDashboard'

//Admin Components
import AdminLayout from './Admin/AdminLayout'
import _Dashboard from './Admin/Dashboard'
import _Items from './Admin/Items/Items'
import _ItemEdit from './Admin/Items/ItemEdit'
import _ItemAdd from './Admin/Items/ItemAdd'
import _Categories from './Admin/Categories/Categories'
import _CategoryAdd from './Admin/Categories/CategoryAdd'
import _CategoryEdit from './Admin/Categories/CategoryEdit'
import _Sizes from './Admin/Sizes/Sizes'
import _SizeEdit from './Admin/Sizes/SizeEdit'
import _SizeAdd from './Admin/Sizes/SizeAdd'
import _Substrates from './Admin/Substrates/Substrates'
import _SubstrateEdit from './Admin/Substrates/SubstrateEdit'
import _SubstrateAdd from './Admin/Substrates/SubstrateAdd'
import _Users from './Admin/Users/Users'
import _UserEdit from './Admin/Users/UserEdit'
import _UserAdd from './Admin/Users/UserAdd'
import _Orders from './Admin/Orders/Orders'
import _OrderEdit from './Admin/Orders/OrderEdit'
import _OrderAdd from './Admin/Orders/OrderAdd'
import _ClientRequests from './Admin/ClientRequests/ClientRequests'
import _ClientRequestView from './Admin/ClientRequests/ClientRequestView'
import _Partners from './Admin/Partners/Partners'
import _PartnerEdit from './Admin/Partners/PartnerEdit'
import _PartnerAdd from './Admin/Partners/PartnerAdd'
import _Contents from './Admin/Content/Contents'
import _ContentEdit from './Admin/Content/ContentEdit'
import PagesList from './Admin/PagesList'
import { call } from './Methods'
import { use } from './Helpers'
import { useSelector, useDispatch } from 'react-redux'
import { saveUser, removeUser } from './Store/userSlice'
import { changeView } from './Store/viewSettingsSlice'
import useWindowDimensions from './Hooks/WindowDimensions'

function App () {
  const { height, width } = useWindowDimensions()
  const dispatch = useDispatch()
  const location = useLocation()
  const [userShort, setUserShort] = useState(null)


  useEffect(() => {
    if (width > 768) {
      console.log('isNotMobile')
      dispatch(
        changeView({
          isMobile: false
        })
      )
    } else {
      console.log('isMobile')
      dispatch(
        changeView({
          isMobile: true
        })
      )
    }
  }, [width])

  const checkUser = async () => {
    let token = use.token()
    if (token) {
      const _userShort = await call.user.checkToken(token)
      if (_userShort?.success) {
        dispatch(saveUser(_userShort.data))
      } else {
        use.resetUserLoginInfo()
        dispatch(removeUser())
      }
    }
  }

  useEffect(() => {
    checkUser()

    WebFont.load({
      google: {
        families: ['Ubuntu:300,400,500,700']
      }
    })
  }, [])

  

  return (
    <React.Fragment>
      <Routes>
        <Route path='/admin' element={<AdminLayout />}>
          <Route path='/admin/dashboard' element={<_Dashboard />} />
          <Route path='/admin/pageslist' element={<PagesList />} />

          {/* Items   */}
          <Route path='/admin/items' element={<_Items />} />
          <Route path='/admin/item/:id' element={<_ItemEdit />} />
          <Route path='/admin/item/add' element={<_ItemAdd />} />

          {/* Categories   */}
          <Route path='/admin/categories' element={<_Categories />} />
          <Route path='/admin/category/:id' element={<_CategoryEdit />} />
          <Route path='/admin/category/add' element={<_CategoryAdd />} />

          {/* Sizes   */}
          <Route path='/admin/sizes' element={<_Sizes />} />
          <Route path='/admin/size/:id' element={<_SizeEdit />} />
          <Route path='/admin/size/add' element={<_SizeAdd />} />

          {/* Substrates   */}
          <Route path='/admin/substrates' element={<_Substrates />} />
          <Route path='/admin/substrate/:id' element={<_SubstrateEdit />} />
          <Route path='/admin/substrate/add' element={<_SubstrateAdd />} />

          {/* Orders   */}
          <Route path='/admin/orders' element={<_Orders />} />
          <Route path='/admin/order/:id' element={<_OrderEdit />} />
          <Route path='/admin/order/add' element={<_OrderAdd />} />

          {/* Client Requests   */}
          <Route path='/admin/clientRequests' element={<_ClientRequests />} />
          <Route
            path='/admin/clientRequests/:id'
            element={<_ClientRequestView />}
          />

          {/* Categories   */}
          <Route path='/admin/partners' element={<_Partners />} />
          <Route path='/admin/partner/:id' element={<_PartnerEdit />} />
          <Route path='/admin/partner/add' element={<_PartnerAdd />} />

          {/* Users   */}
          <Route path='/admin/users' element={<_Users />} />
          <Route path='/admin/user/:id' element={<_UserEdit />} />
          <Route path='/admin/user/add' element={<_UserAdd />} />

          {/* Contents   */}
          <Route path='/admin/contents' element={<_Contents />} />
          <Route path='/admin/content/:id' element={<_ContentEdit />} />
        </Route>

        <Route path='/' element={<ClientLayout />}>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route
            path='/terms-and-conditions'
            element={<TermsAndConditions />}
          />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/shipping-policy' element={<ShippingPolicy />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/item/:id' element={<Item />} />
          <Route path='/items' element={<Items />} />
          {/* <Route path="/items" element={<Items />} /> */}

          <Route path='/dashboard/:userName?' element={<ClientDashboard />} />
          <Route path='/checkout' element={<CheckOut />} />

          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password/:guid' element={<ResetPassword />} />
        </Route>

        <Route path='/redirect' element={<Redirect />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/payment/:status' element={<PaymentResult />} />

        <Route path='/*' element={<NotFound />} />
      </Routes>
    </React.Fragment>
  )
}

export default App
