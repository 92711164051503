import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Table from '../_Components/AdminTable'
import Variables from '../../global'
import { call } from '../../Methods'
import { use } from '../../Helpers'

function _Items () {
  const [tableData, setTableData] = useState()
  const [searchParams, setSearchParams] = useSearchParams();



  let tableColumns = [
    {
      title: 'Image',
      property: 'image',
      type: 'image',
      mutate: value => {
        return `${Variables.base_url}/api/images/thumbnail-sm/${value}`
      }
    },
    {
      title: 'Serial',
      property: 'itemNumber'
    },
    {
      title: 'Name',
      property: 'itemName'
    },
    {
      title: 'Status',
      property: 'statusName',
      type: 'label',
      mutate: value => {
        switch (value.toLowerCase()) {
          case 'published': {
            value = <span className='label-blue'>published</span>
            break
          }
          case 'draft': {
            value = <span className='label-green'>draft</span>
            break
          }
          default: {
            value = <span className='label-yellow'>{value}</span>
            break
          }
        }
        return value
      }
    },
    {
      title: 'Actions',
      type: 'actions-list',
      property: ['id'],
      mutate: value => {
        return (
          <>
            <Link to={`/admin/item/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getData = async parameters => {
    let _items = await call.forLoggedUser.item.getItems(parameters)
    setTableData(_items)
    return _items
  }

  useEffect(()=>{
    getData(use.userSettings());
  },[use.userSettings()])

  return (
    <React.Fragment>
      <h3 className='page-title'>Items</h3>
      {tableData && tableData.data &&<Table
        columns={tableColumns}
        loading={tableData.data?false:true}
        data={tableData.data}
        addNew={{ enabled: true, url: '/admin/item/add' }}
        pagination={{
          enabled: true,
          enable:true,
          settings:{
            pageNumber: +searchParams.get("pageNumber") || 1,
            pageSize: +searchParams.get("pageSize") || 10,
            sort: searchParams.get("sort") || 'asc',
            length: tableData.length || 0,
            pageCount: tableData.pageCount || 0
          }
        }}
      />}
    </React.Fragment>
  )
}

export default _Items
