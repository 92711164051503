import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { use } from '../Helpers'
import { call } from '../Methods'
import { useSelector, useDispatch } from 'react-redux'
import { saveUser } from '../Store/userSlice'
import Table from '../Admin/_Components/AdminTable'
import Input from '../Admin/_Components/Input'
import { removeUser } from '../Store/userSlice'
import ValidateInput from '../ValidationDefaults'

function ClientDashboard () {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { userName } = useParams()
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState()
  const [editMode, setEditMode] = useState(false)
  const [changed, setChanged] = useState(false)
  const [userShort, setUserShort] = useState(null)
  const [userDetails, setUserDetails] = useState(null)

  const checkUser = async () => {
    setLoading(true);
    const _userShort = await use.userShort();

    if (_userShort){
      const _userDetails = await call.forLoggedUser.user.getUser({
        id: _userShort.id
      })
      if (_userDetails.success) {
        setUserDetails(_userDetails.data)
        console.log(_userDetails.data)
      }
    }else{
      navigate('/login', { replace: true })
      use.resetUserLoginInfo();
      dispatch(removeUser())
    }
    setLoading(false)
  }

  let colorsByValues = {
    '-2': '#ff5252',
    '-1': '#fcba03',
    0: '#FF9800',
    1: '#4242c4',
    2: '#c442c4',
    3: '#42c442',
    4: '#c4c442',
    5: '#42c490',
    6: '#42c4c4',
    7: '#ff5252',
    8: '#fcba03',
    9: '#ff9800'
  }

  const defaultParameters = {
    enabled: ''
  }

  const userDefinedParameters = JSON.parse(
    localStorage.getItem('tpp_userSettings')
  ).searchSettings

  let _parameters = {}
  Object.assign(
    _parameters,
    defaultParameters,
    userDefinedParameters.searchSettings
  )

  const [parameters, setParameters] = useState(_parameters)

  let tableColumns = [
    {
      title: 'Order Number',
      property: 'orderNumber'
    },
    {
      title: 'Status',
      property: ['statusId', 'statusName'],
      type: 'label',
      mutate: values => {
        return (
          <span style={{ backgroundColor: colorsByValues[values[0]] }}>
            {values[1]}
          </span>
        )
      }
    },
    {
      title: 'Date',
      property: 'createDate'
    },
    {
      title: 'Actions',
      property: 'id',
      type: 'actions-list',
      mutate: value => {
        return (
          <>
            <Link to={`/dashboard/orderView/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/view.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getOrders = async () => {
    setLoading(true)
    const _orders = await call.forLoggedUser.order.getOrders({})
    console.log(_orders)
    setTableData(_orders.data)
    setLoading(false)
    return _orders
  }

  useEffect(() => {
    getOrders()
  }, [])

  useEffect(() => {
    checkUser()
  }, [])

  //User Events

  const changePage = e => {
    let tabs = document.querySelectorAll('.tabs .tab-button')
    let pages = document.querySelectorAll('.tab-pages .tab-page')
    ;[...tabs].forEach(tab => {
      tab.classList.remove('selected')
    })
    e.target.classList.add('selected')
    ;[...pages].forEach(page => {
      page.classList.remove('open')
    })
    let tabIndex = e.target.dataset['tab']
    document
      .querySelector(`.tab-pages .tab-page[data-page="${tabIndex}"]`)
      .classList.add('open')
  }

  const activateEditMode = async () => {
    setEditMode(true)
  }

  const changePassword = async (e) => {
    try{
      e.preventDefault();
      setLoading(true);    
      let validationResults = []
      
      const ValidateAs = {
        oldPassword: ['required'],
        newPassword: ['required', 'password'],
        reenteredPassword: ['required', 'password']
      }

      let _formValues = {
        oldPassword: '',
        newPassword: '',
        reenteredPassword: ''
      }

    
      ;[...e.target.elements].forEach(input => {
        if (input.type === 'submit') {
          return //continue
        }
        _formValues[input.name] = input.value
        if (ValidateAs[input.name]) {
          let errorSpan = document.querySelector(
            `span.warning-message[for=${input.name}]`
          )
  
          let validation = ValidateInput(
            input.name,
            ValidateAs[input.name],
            input.value
          )
          validationResults.push(validation)
  
          if (!validation.result) {
            errorSpan.classList.add('show')
            errorSpan.innerText = validation?.message
          }
        }
      })
  
      let validationResult = validationResults.every(r => r.result === true)
      
      if (validationResult) {
        if(_formValues.newPassword!==_formValues.reenteredPassword){
          alert('Passwords don\'t match');
          return;
        }
        const _result = await call.forLoggedUser.user.changePassword(_formValues)
        if (_result.success) {
          alert('Success')
        }
      }
    }catch(x){
      alert('Failed to change the password. Please, try again.', x)
    }finally{
      setLoading(false)
    }
  }

  const saveSettings = async (e) => {
    try{
      console.log(e.target)
      e.preventDefault();
      setLoading(true);    
      let validationResults = []
      
      const ValidateAs = {
        firstName: ['name','required'],
        lastName:['name','required'],
        companyName:['name','required'],
        phone:['required'],
        address:['required'],
        postalCode:['CanadaPostCode','required']
      }

      let _formValues = {
        firstName: '',
        lastName:'',
        companyName:'',
        phone:'',
        address:'',
        postalCode:''
      }

      
      ;[...e.target.elements].forEach(input => {
        console.log(input.name,input.value)
        if (input.type === 'submit') {
          return //continue
        }
        _formValues[input.name] = input.value
        if (ValidateAs[input.name]) {
          let errorSpan = document.querySelector(
            `span.warning-message[for=${input.name}]`
          )
  
          let validation = ValidateInput(
            input.name,
            ValidateAs[input.name],
            input.value
          )
          console.log(validation);
          validationResults.push(validation)
  
          if (!validation.result) {
            errorSpan.classList.add('show')
            errorSpan.innerText = validation?.message
          }
        }
      })
  
      let validationResult = validationResults.every(r => r.result === true)
      
      if (validationResult) {
        const _result = await call.forLoggedUser.user.updateUser(_formValues)
        if (_result.success) {
          setUserDetails(_result.data)
          alert('Success')
        }
      }
    }catch(x){
      alert('Failed to update user. Please, try again.', x.message)
    }finally{
      setLoading(false)
    }
    
    
    
    
  }

  if (loading) return <div className='loading'>Loading...</div>
  if (!tableData) return <div className='loading'>Something wrong happen...</div>
  return (
    <React.Fragment>
      {userDetails && <div className='page client-dashboard-page'>
        <div className='client-dashboard'>
          <div className='tab'>
            <div className='tabs'>
              <div
                data-tab='1'
                className='tab-button'
                onClick={e => changePage(e)}
              >
                Orders
              </div>
              <div
                data-tab='2'
                className='tab-button selected'
                onClick={e => changePage(e)}
              >
                Settings
              </div>
            </div>
            <div className='tab-pages'>
              <div className='tab-page' data-page='1'>
                <Table
                  parameters={parameters}
                  columns={tableColumns}
                  loading={tableData ? false : true}
                  data={tableData}
                  pagination={{ enable: false }}
                />
              </div>
              <div className='tab-page open' data-page='2'>
                <div className='settings-page'>
                  <div className='user-info'>
                    {userDetails && (
                      <form className='form' onSubmit={(e)=>saveSettings(e)}>
                        <h5 className='form-title'>
                          Personal Info
                          <button
                            className='edit'
                            type='button'
                            onClick={() => {
                              setEditMode(!editMode)
                            }}
                          >
                            {!editMode ? (
                              <>
                                <img src='/Icons/edit.png' alt='edit icon' />
                                Edit
                              </>
                            ) : (
                              <>
                                <img src='/Icons/close.png' alt='close icon' />
                                Cancel
                              </>
                            )}
                          </button>
                        </h5>
                        <Input
                          type={'text'}
                          fieldName={'email'}
                          label={'Email'}
                          disabled={true}
                          col={{ default: 12, sm: 9, md: 9 }}
                          value={userDetails.email}
                        />
                        <Input
                          type={'text'}
                          fieldName={'firstName'}
                          label={'First Name'}
                          disabled={!editMode}
                          col={{ default: 12, sm: 9, md: 9 }}
                          value={userDetails.firstName}
                        />
                        <Input
                          type={'text'}
                          fieldName={'lastName'}
                          label={'LastName'}
                          disabled={!editMode}
                          col={{ default: 12, sm: 9, md: 9 }}
                          value={userDetails.lastName}
                        />
                        <Input
                          type={'text'}
                          fieldName={'companyName'}
                          label={'Company Name'}
                          disabled={!editMode}
                          col={{ default: 12, sm: 9, md: 9 }}
                          value={userDetails.companyName}
                        />
                        <Input
                          type={'text'}
                          fieldName={'phone'}
                          label={'Phone Number'}
                          disabled={!editMode}
                          col={{ default: 12, sm: 9, md: 9 }}
                          value={userDetails.phone}
                        />
                        <Input
                          type={'text'}
                          fieldName={'address'}
                          label={'Address'}
                          disabled={!editMode}
                          col={{ default: 12, sm: 9, md: 9 }}
                          value={userDetails.address}
                        />
                        <Input
                          type={'text'}
                          fieldName={'postalCode'}
                          label={'Postal Code'}
                          disabled={!editMode}
                          col={{ default: 12, sm: 9, md: 9 }}
                          value={userDetails.postalCode}
                        />
                        <div className='button-box'>
                          <button
                            type='submit'
                            style={{ display: editMode ? 'block' : 'none' }}
                            className='button button-default'
                          >
                            Save Settings
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                  <div className='password'>
                    {userDetails && (
                      <form className='form'>
                        <h5 className='form-title'>Account Settings</h5>
                        <Input
                          type={'text'}
                          fieldName={'userName'}
                          label={'Username'}
                          col={{ default: 12, sm: 6, md: 6 }}
                          value={userDetails.userName}
                        />{' '}
                        <Input
                          type={'text'}
                          fieldName={'email'}
                          label={'Email'}
                          col={{ default: 12, sm: 6, md: 6 }}
                          value={userDetails.email}
                        />
                      </form>
                    )}
                    <form className='form' onSubmit={(e) => changePassword(e)}>
                      <h5 className='form-title'>Change Password</h5>
                      <Input
                        type={'password'}
                        fieldName={'oldPassword'}
                        label={'Old Password'}
                        col={{ default: 12, sm: 9, md: 9 }}
                        value={''}
                      />
                      <Input
                        type={'password'}
                        fieldName={'newPassword'}
                        label={'New Password'}
                        col={{ default: 12, sm: 9, md: 9 }}
                        value={''}
                      />
                      <Input
                        type={'password'}
                        fieldName={'reenteredPassword'}
                        label={'Re-enter Your Password'}
                        col={{ default: 12, sm: 9, md: 9 }}
                        value={''}
                      />
                      <div className='button-box'>
                        <button type='submit' className='button button-default'>
                          Update Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </React.Fragment>
  )
}

export default ClientDashboard
